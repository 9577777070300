<template>
  <v-row>
    <v-col cols="4">
      <v-select
        v-model="status"
        dense
        hide-details
        :items="statuses"
      />
    </v-col>
    <v-col cols="8">
      <v-text-field
        v-model="keyword"
        label="Keyword"
        placeholder="검색어를 입력해주세요."
        dense
        hide-details
        append-outer-icon="mdi-magnify"
        append-icon="mdi-close"
        @keydown="enterSearch"
        @click:append="searchInit"
        @click:append-outer="search"
      />
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ManageCompanySearch',
    props: [
      'companies',
    ],
    data () {
      return {
        keyword: this.$route.query.keyword ? this.$route.query.keyword : '',
        status: this.$route.query.status ? this.$route.query.status : '',
        statuses: [
          {
            text: '전체',
            value: '',
          },
          {
            text: '진행',
            value: 'pending',
          },
          {
            text: '완료',
            value: 'confirm',
          },
        ],
      }
    },
    methods: {
      search () {
        const params = {
          keyword: this.keyword,
          status: this.status,
        }
        this.$emit('search', params)
      },
      searchInit () {
        this.keyword = ''
        this.status = ''
        this.search()
      },
      enterSearch () {
        if (event.keyCode === 13) {
          this.search()
        }
      },
    },
  }
</script>

<style scoped>

</style>
