<template>
  <v-card>
    <v-card-title>
      <v-icon class="mr-2">
        mdi-sitemap-outline
      </v-icon>
      <h5>
        업체 상품목록
      </h5>
      <v-spacer />
      <v-btn
        icon
        @click="close"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle>
      <v-select
        v-model="product"
        :items="products"
        label="상품목록"
        @change="getDates"
      >
        <template #item="{item}"><span v-html="item.newText" /></template>
        <template #selection="{item}"><span v-html="item.text" /></template>
      </v-select>
    </v-card-subtitle>
    <v-card-text>
      <template v-for="(item, i) in dates">
        <p :key="'date-' + i" class="my-2 d-flex align-center">
          {{ $parseDate(item) }}
          <v-spacer />
          <v-btn
            icon
            small
            @click="download($parseDate(item))"
          >
            <v-icon>
              mdi-download
            </v-icon>
          </v-btn>
        </p>
        <v-divider :key="'date-divider-' + i" />
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'CompanyProducts',
    props: {
      company: Object,
    },
    data () {
      return {
        products: [],
        product: null,
        dates: [],
      }
    },
    watch: {
      product () {
        this.getDates()
      },
    },
    beforeMount () {
      this.getProducts()
    },
    methods: {
      async getProducts () {
        const url = `/manage/company/${this.company.idx}/products`
        await this.$axios.get(url).then(res => {
          if (res && res.data) {
            this.products = res.data
          }
        })
      },
      async getDates () {
        if (this.product) {
          const url = '/manage/product/purchase/dates'
          const params = {
            productIdx: this.product,
          }
          await this.$axios.get(url, { params: params }).then(res => {
            if (res && res.data) {
              this.dates = res.data
              this.dates = this.dates.reverse()
            }
          })
        } else {
          this.dates = []
        }
      },
      async download (date) {
        const url = '/manage/product/company/download'
        const params = {
          date: date,
          productIdx: this.product,
        }
        this.$axios.get(url, {
          responseType: 'blob',
          params: params,
        }).then(res => {
          if (res.data && res.data.size > 0) {
            const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }))
            const fileName = this.company.companyName + '_' + date + '.xlsx'
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
          } else {
            alert('파일 다운로드에 실패하였습니다.')
          }
        })
      },
      close () {
        this.$emit('close-product')
      },
    },
  }
</script>

<style scoped>

</style>
