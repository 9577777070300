<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
  >
    <v-container>
      <ManageCompanyHeader />
      <div class="text-right">
        <v-dialog
          v-model="company.showUp"
          :max-width="mobile ? '100%' : '600'"
          :fullscreen="mobile"
          :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
          persistent
        >
          <template #activator="{ attrs, on }">
            <v-btn
              class="mt-2"
              :width="mobile ? '100%' : ''"
              v-bind="attrs"
              v-on="on"
            >
              업체등록
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="pt-10 pt-md-3">
              <v-icon class="mr-2">
                mdi-storefront-edit-outline
              </v-icon>
              <h5>
                업체 상세정보
              </h5>
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="company.companyName"
                label="Name"
                placeholder="업체명"
              />
              <v-text-field
                v-model="company.companyFixedPoint"
                type="number"
                hide-spin-buttons
                label="Fixed Point"
                placeholder="업체 리뷰 포인트를 입력해주세요."
              />
              <v-text-field
                v-model="company.companyManager"
                label="Manager"
                placeholder="담당자를 입력해주세요."
              />
              <v-text-field
                v-model="company.companyTel"
                label="Tel"
                placeholder="업체 전화번호를 입력해주세요."
              />
              <v-textarea
                v-model="company.companyMemo"
                label="Memo"
                no-resize
                hide-details
                placeholder="메모를 입력해주세요."
              />
            </v-card-text>
            <v-card-actions class="flex-action">
              <v-btn class="mx-0 mb-2" width="100%" @click="registerCompany()">
                저장
              </v-btn>
              <v-btn class="mx-0 mb-2" width="100%" @click="closeDetail(company)">
                창닫기
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <ManageCompanySearch @search="search" />
      <ManageCompanyContent ref="manageCompanyContent" :pagination="pagination" @refresh="getPagination" @update-stauts="updateStatus" />
      <v-sheet class="px-3 pb-15 pb-md-3">
        <v-row>
          <v-col cols="12" md="2" class="text-left">
            <v-btn
              class="mt-2"
              @click="updateStatus('pending')"
            >
              진행
            </v-btn>
            <v-btn
              class="mt-2 ml-1"
              @click="updateStatus('confirm')"
            >
              완료
            </v-btn>
          </v-col>
          <v-col cols="12" md="8">
            <Pagination ref="pagination" :pagination="pagination" :show-num="7" :base-url="'/manage/company/'" :query="$route.query" />
          </v-col>
          <v-col cols="12" md="2" class="text-right">
            <v-dialog
              v-model="company.show"
              :max-width="mobile ? '100%' : '600'"
              :fullscreen="mobile"
              :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
              persistent
            >
              <template #activator="{ attrs, on }">
                <v-btn
                  class="mt-2"
                  :width="mobile ? '100%' : ''"
                  v-bind="attrs"
                  v-on="on"
                >
                  업체등록
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="pt-10 pt-md-3">
                  <v-icon class="mr-2">
                    mdi-storefront-edit-outline
                  </v-icon>
                  <h5>
                    업체 상세정보
                  </h5>
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="company.companyName"
                    label="Name"
                    placeholder="업체명"
                  />
                  <v-text-field
                    v-model="company.companyFixedPoint"
                    type="number"
                    hide-spin-buttons
                    label="Fixed Point"
                    placeholder="업체 리뷰 포인트를 입력해주세요."
                  />
                  <v-text-field
                    v-model="company.companyManager"
                    label="Manager"
                    placeholder="담당자를 입력해주세요."
                  />
                  <v-text-field
                    v-model="company.companyTel"
                    label="Tel"
                    placeholder="업체 전화번호를 입력해주세요."
                  />
                  <v-textarea
                    v-model="company.companyMemo"
                    label="Memo"
                    no-resize
                    hide-details
                    placeholder="메모를 입력해주세요."
                  />
                </v-card-text>
                <v-card-actions class="flex-action">
                  <v-btn class="mx-0 mb-2" width="100%" @click="registerCompany()">
                    저장
                  </v-btn>
                  <v-btn class="mx-0 mb-2" width="100%" @click="closeDetail(company)">
                    창닫기
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
  </v-lazy>
</template>

<script>
  import ManageCompanyHeader from '@/views/manage/company/Header'
  import ManageCompanyContent from '@/views/manage/company/Content'
  import ManageCompanySearch from '@/views/manage/company/Search'
  import Pagination from '@/components/core/Pagination'
  export default {
    name: 'Company',
    components: { Pagination, ManageCompanySearch, ManageCompanyContent, ManageCompanyHeader },
    data () {
      return {
        company: {
          companyName: null,
          companyManager: null,
          companyTel: null,
          companyMemo: null,
          show: false,
          showUp: false,
        },
        pagination: {
          list: [],
          pageNum: this.pageNum ? this.pageNum : 1,
        },
        keyword: this.$route.query.keyword ? this.$route.query.keyword : '',
        status: this.$route.query.status ? this.$route.query.status : '',
      }
    },
    computed: {
      pageNum () {
        return this.$route.params.pageNum
      },
      mobile () {
        return this.$isMobile
      },
    },
    watch: {
      pageNum () {
        this.getPagination()
      },
    },
    beforeMount () {
      this.getPagination()
    },
    methods: {
      async getPagination () {
        const url = `/manage/company/companies`
        const params = {
          keyword: this.keyword,
          pageNum: this.pageNum,
          status: this.status,
        }
        await this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      async updateStatus (status) {
        const idxes = this.$refs.manageCompanyContent.selected
        if (!idxes || idxes.length < 1) {
          alert('업체를 선택해주세요.')
          return
        }
        const url = `/manage/company/companies/status/${status}`
        await this.$axios.post(url, idxes).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            this.getPagination()
            this.$refs.manageCompanyContent.selected = []
          }
        })
      },
      search (params) {
        this.keyword = params.keyword
        this.status = params.status
        this.pagination.pageNum = 1
        this.getPagination()
        this.$router.push(`/manage/company/1?keyword=${this.keyword}&status=${this.status}`)
      },
      async registerCompany () {
        if (confirm('업체를 등록하시겠습니까?')) {
          const url = '/manage/company'
          this.$axios.post(url, this.company).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              alert('처리가 완료되었습니다.')
              this.getPagination()
              this.company = {
                companyName: null,
                companyManager: null,
                companyTel: null,
                companyMemo: null,
                companyFixedPoint: null,
                show: false,
              }
            } else {
              alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
            }
          }).catch(e => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
      closeDetail (company) {
        company.show = false
        company.showUp = false
      },
    },
  }
</script>

<style scoped>

</style>
