<template>
  <v-row>
    <v-col cols="12" md="12">
      <div>
        <v-subheader>
          업체관리
        </v-subheader>
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th>
                  <v-checkbox v-model="checkAll" />
                </th>
                <th
                  v-for="(col, i) in cols"
                  :id="'company-' + i"
                  :key="i"
                  class="text-subtitle-1 text-center"
                >
                  {{ col }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in pagination.list" :key="'company-' + item.idx">
                <td>
                  <v-checkbox v-model="selected" :value="item.idx" />
                </td>
                <td class="text-center">
                  <span class="text-truncate">
                    <template v-if="item.new">
                      <v-badge content="New" color="red">
                        {{ item.companyName }}
                      </v-badge>
                    </template>
                    <template v-else>
                      {{ item.companyName }}
                    </template>
                  </span>
                </td>
                <td class="text-center">
                  <span class="text-truncate">
                    {{ item.strStatus }}
                  </span>
                </td>
                <td class="text-center">
                  <span class="text-truncate">
                    {{ item.companyManager }}
                  </span>
                </td>
                <td class="text-center">
                  <span class="text-truncate">
                    {{ item.companyTel }}
                  </span>
                </td>
                <td class="text-center">
                  <v-dialog
                    v-model="item.showExcel"
                    :max-width="mobile ? '100%' : '600'"
                    persistent
                  >
                    <template #activator="{ attrs, on }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>
                          mdi-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <CompanyProducts v-if="item.showExcel" :company="item" @close-product="item.showExcel = false" />
                  </v-dialog>
                  <v-dialog
                    v-model="item.show"
                    :max-width="mobile ? '100%' : '600'"
                    persistent
                  >
                    <template #activator="{ attrs, on }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>
                          mdi-storefront-edit-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <v-icon class="mr-2">
                          mdi-storefront-edit-outline
                        </v-icon>
                        <h5>
                          업체 상세정보
                        </h5>
                        <v-btn
                          color="error"
                          x-small
                          class="ml-2"
                          @click="deleteCompany(item)"
                        >
                          삭제
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-text-field
                          v-model="item.companyName"
                          label="Name"
                          placeholder="업체명"
                        />
                        <v-text-field
                          v-model="item.companyFixedPoint"
                          label="Fixed Point"
                          type="number"
                          hide-spin-buttons
                          placeholder="업체 리뷰 포인트를 입력해주세요."
                        />
                        <v-text-field
                          v-model="item.companyManager"
                          label="Manager"
                          placeholder="담당자를 입력해주세요."
                        />
                        <v-text-field
                          v-model="item.companyTel"
                          label="Tel"
                          placeholder="업체 전화번호를 입력해주세요."
                        />
                        <v-textarea
                          v-model="item.companyMemo"
                          label="Memo"
                          no-resize
                          hide-details
                          placeholder="메모를 입력해주세요."
                        />
                        <v-textarea
                          v-model="item.blackList"
                          label="BlackList"
                          no-resize
                          hide-details
                          placeholder="아이디를 입력해주세요. , 로 구분됩니다."
                        />
                      </v-card-text>
                      <v-card-actions class="flex-action">
                        <v-btn class="mx-0 mb-2" width="100%" @click="saveCompany(item)">
                          저장
                        </v-btn>
                        <v-btn class="mx-0 mb-2" width="100%" @click="closeDetail(item)">
                          창닫기
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import CompanyProducts from '@/components/company/CompanyProducts'
  export default {
    name: 'ManageCompanyContent',
    components: { CompanyProducts },
    props: {
      pagination: Object,
    },
    data () {
      return {
        cols: ['업체명', '진행상태', '담당자', '연락처', '상세보기'],
        isLoading: false,
        selected: [],
      }
    },
    computed: {
      checkAll: {
        get () {
          return this.pagination ? (this.selected ? (this.selected.length === this.pagination.list) : false) : false
        },
        set (value) {
          const selected = []
          if (value) {
            this.pagination.list.forEach(user => {
              selected.push(user.idx)
            })
            this.selected = selected
          } else {
            this.selected = []
          }
        },
      },
      mobile () {
        return this.$isMobile
      },
    },
    methods: {
      searchCompany (keyword) {
        this.keyword = keyword
        this.$router.push(`/manage/company/1?keyword=${keyword}`)
        this.refresh()
      },
      searchInit () {
        this.keyword = null
        this.$router.push('/manage/company/1')
        this.refresh()
      },
      async saveCompany (company) {
        if (confirm('업체를 수정하시겠습니까?')) {
          const url = '/manage/company'
          this.$axios.patch(url, company).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              alert('처리가 완료되었습니다.')
              this.refresh()
            } else {
              alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
            }
          }).catch(e => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
      async deleteCompany (company) {
        if (confirm('해당 업체에 등록된 모든 게시글이 함께 삭제됩니다.\r\n삭제하시겠습니까?')) {
          const url = '/manage/company'
          this.$axios.delete(url, { data: company }).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              alert('처리가 완료되었습니다.')
              this.refresh()
            } else {
              alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
            }
          }).catch(e => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
      closeDetail (company) {
        company.show = false
        company.showUp = false
      },
      updateStatus (status) {
        const params = {
          status: status,
          idxes: this.selected,
        }
        this.$emit('update-status', params)
      },
      refresh () {
        this.$emit('refresh')
      },
    },
  }
</script>

<style scoped lang="scss">
  *::v-deep {
    v-messages {
      display: none;
    }
  }
</style>
